

























import vesselEnquiryEditRoutes from "@/router/vesselEnquiryEdit";
import { getModule } from "vuex-module-decorators";
import { Vue, Component } from "vue-property-decorator";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);

@Component
export default class VesselEnquiryEditIndex extends Vue {
  private get routes() {
    return vesselEnquiryEditRoutes;
  }

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }
}
